import { Image } from "@chakra-ui/react";

function Player() {

  return (
    <>
      <Image src={require(`../Img/SWG/Instinction.jpg`)} />
    </>
  );
}

export default Player;