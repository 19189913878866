import Banner from "../Components/Banner";
import Hero from "../Components/Hero";

function Home() {
  return (
    <>
        <Hero />
    </>
  );
}

export default Home;