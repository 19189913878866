import { Text } from "@chakra-ui/react";

function AboutTeam() {

  return (
    <>
                    <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eleifend pretium quam at interdum. Fusce eget vehicula justo. Fusce elementum risus tortor, et vestibulum tortor pellentesque ac. Morbi sit amet malesuada massa. Fusce venenatis dolor id enim faucibus laoreet. Maecenas sit amet ligula venenatis massa elementum porta sit amet ut leo. Vivamus laoreet pellentesque nulla, et viverra purus maximus nec. Sed tincidunt ligula non urna molestie vehicula quis a metus. Ut in purus ac lacus luctus vulputate et vitae diam. Etiam vehicula volutpat nibh. Pellentesque ut mattis tortor. Suspendisse a ante vel ipsum finibus elementum.

Integer sed semper nisi, quis viverra arcu. Vivamus id erat ac leo vulputate tempor eu a lacus. Maecenas quis tempus justo. Fusce egestas luctus nibh, ac congue est maximus quis. Curabitur nisl mi, iaculis eget eleifend id, vestibulum ut tellus. Curabitur in pretium odio. In eget tincidunt metus, eget dignissim ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla metus nunc, suscipit at vulputate ut, rutrum sed nisl.

Nullam placerat non augue sit amet mollis. Aenean maximus molestie odio, ac auctor nibh fringilla gravida. Etiam posuere fermentum neque, vitae semper magna ultricies vel. Quisque posuere vel risus sodales dictum. Nam ornare lorem purus, id scelerisque diam scelerisque et. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer semper ante quam, non lobortis elit maximus eu. Aliquam mauris mi, eleifend non sollicitudin accumsan, hendrerit sit amet arcu. Donec nec iaculis velit, eu sagittis tellus. Donec scelerisque laoreet nisl id sagittis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus vestibulum, felis vitae vestibulum ultrices, turpis nibh gravida eros, egestas luctus dui magna vitae augue. Praesent libero dolor, pharetra eget tortor eu, condimentum interdum massa. Aliquam ac felis accumsan, sagittis risus nec, suscipit urna. Quisque efficitur, quam ut porta dictum, nisi urna suscipit quam, interdum feugiat elit eros at diam.

Sed condimentum eros eu velit feugiat convallis. Suspendisse viverra turpis ac eros gravida tincidunt. Proin sit amet diam massa. Integer dolor mi, iaculis id tincidunt efficitur, finibus vitae erat. Proin ornare sollicitudin dolor mollis fermentum. Vestibulum efficitur et dui eget dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque eget luctus nisl. Nullam eu vestibulum metus.

In sit amet urna a eros viverra imperdiet vitae a ligula. In pretium turpis posuere, aliquam orci id, malesuada nulla. Cras vestibulum orci justo, id sodales eros cursus nec. Praesent eu leo gravida, suscipit lacus sit amet, bibendum nunc. Vestibulum id mi non elit commodo euismod sed sit amet odio. Vivamus et feugiat tellus. Morbi scelerisque fringilla tellus ac pellentesque. In vulputate quis arcu ac vehicula. Donec a sapien ac nisl blandit convallis non ut sapien. In augue lectus, elementum sit amet nulla in, gravida maximus dui. Nunc ut magna eu ipsum sodales vulputate. Phasellus commodo magna nulla, eget dignissim metus facilisis a. Sed orci diam, molestie vitae justo vitae, cursus tincidunt augue. Suspendisse tristique leo dui, non pharetra quam ultricies at. Praesent ipsum est, feugiat nec venenatis vel, scelerisque ut risus.</Text>
    </>
  );
}

export default AboutTeam;